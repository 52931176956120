
import Component from '../../../scripts/modules/component'

export default class FAQAccordeonComponent extends Component {
  init () {
    for (const anchor of [...this.element.querySelectorAll('.faq-accordeon__item-header')]) {
      anchor.addEventListener('click', event => this.toggleItem(event))
    }
  }

  update () {
    //
  }

  destroy () {
    //
  }

  toggleItem (event) {
    event.preventDefault()
    event.target.parentNode.classList.toggle('faq-accordeon__item--expanded')
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.faq-accordeon')) {
    element.instance = element.instance || new FAQAccordeonComponent(element)
  }
})
