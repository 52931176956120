
import Component from '../../../scripts/modules/component'
import fireCustomEvent from './../../../vendor/scripts/fabrique/modules/fire-custom-event'

export default class ArticleCookiesComponent extends Component {
  init () {
    this.buttonChange = this.element.querySelector('.button--change')
    this.buttonChange?.addEventListener('click', event => {
      event.preventDefault()
      fireCustomEvent('show-cookie-details')
    })

    this.buttonClear = this.element.querySelector('.button--clear')
    this.buttonClear?.addEventListener('click', event => {
      event.preventDefault()
      fireCustomEvent('clear-cookie-settings')
    })
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.article-cookies')) {
    element.instance = element.instance || new ArticleCookiesComponent(element)
  }
})
