
import Component from '../../../scripts/modules/component'
// import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
// import focusTrap from 'focus-trap'

const BREAKPOINT_NOTEBOOK = 1180
const TIMEOUT_DELAY = 350

let timeout

export default class DeluxeMenuBarComponent extends Component {
  init () {

    // is menu sticky
    this.isStickyMenu = this.element.dataset.isSticky === 'True'

    if (this.isStickyMenu) {
      document.documentElement.classList.add('menu-is-sticky')
    }

    this.toggleOverlayButtons = [...this.element.querySelectorAll('.button--menu')]
    this.menuOverlay = document.getElementById('menu-overlay')
    this.menuLinksContainer = this.element.querySelector('.deluxe-menu-bar__menu-links')
    this.submenus = [...this.element.querySelectorAll('.deluxe-menu-bar__submenu')]
    this.linksWithSubmenu = [...this.element.querySelectorAll('a[aria-haspopup="true"]:not(.link--back), .button--search, .button--language')]
    this.backLinks = [...this.element.querySelectorAll('.link--back')]
    this.background = this.element.querySelector('.deluxe-menu-bar__overlay-background')
    this.searchbox = this.element.querySelector('.form-field--search input')

    this.overlayFocusTraps = {}

    this.initOverlay()
    this.initSubmenus()
    this.initBackground()

    window.addEventListener('resize', debounce(() => this.onWindowResize(), 150, { leading: true, trailing: true }))
    this.onWindowResize()
  }

  update () {
    //
  }

  destroy () {
    //
  }

  onWindowResize () {
    if (window.innerWidth < BREAKPOINT_NOTEBOOK) {
      this.menuOverlay.setAttribute('role', 'dialog')
      this.menuLinksContainer.setAttribute('role', 'menu')
    } else {
      this.menuOverlay.removeAttribute('role', 'dialog')
      this.menuLinksContainer.setAttribute('role', 'menubar')

      // Force close menu if open on level 0
      if (document.documentElement.classList.contains('overlay-visible') && document.querySelector('.deluxe-menu-bar__submenu--visible[data-level="0"]')) {
        this.toggleMenuOverlay()

        window.clearTimeout(timeout)
        timeout = window.setTimeout(() => this.toggleSubmenu, TIMEOUT_DELAY)
      }
    }
  }

  setDocumentClasses () {
    const overlayIsOpen = document.querySelector('.deluxe-menu-bar--overlay-visible')
    const submenuIsOpen = document.querySelector('.deluxe-menu-bar__submenu--visible')

    document.documentElement.classList.toggle('overlay-visible', overlayIsOpen)
    document.documentElement.classList.toggle('submenu-visible', submenuIsOpen)
    document.documentElement.classList.toggle('prevent-scrolling', overlayIsOpen)
  }

  initOverlay () {
    for (const button of this.toggleOverlayButtons) {
      button.addEventListener('click', event => this.toggleMenuOverlay(event))
    }

    document.addEventListener('keydown', event => this.onDocumentKeyDown(event))
  }

  onDocumentKeyDown (event) {
    if (!this.element.classList.contains('deluxe-menu-bar--overlay-visible')) {
      return
    }

    if (event.keyCode === 27) {
      this.toggleMenuOverlay()
    }
  }

  toggleMenuOverlay (event) {
    if (event) {
      event.preventDefault()
    }

    if (!this.isStickyMenu) {
      window.scrollTo(0, 0)
    }

    if (this.element.classList.contains('deluxe-menu-bar--overlay-visible')) {
      this.element.classList.remove('deluxe-menu-bar--overlay-visible')

      for (const link of this.linksWithSubmenu) {
        link.setAttribute('aria-expanded', false)
      }

      window.clearTimeout(timeout)
      timeout = window.setTimeout(() => {
        this.toggleSubmenu(document.querySelector('.deluxe-menu-bar__submenu[data-level="0"]'))
      }, TIMEOUT_DELAY)
    } else {
      this.element.classList.add('deluxe-menu-bar--overlay-visible')
    }

    this.setDocumentClasses()
  }

  initSubmenus () {
    for (const link of this.linksWithSubmenu) {
      link.setAttribute('aria-expanded', false)
      link.addEventListener('click', event => this.clickLink(link, event))
    }

    for (const link of this.backLinks) {
      link.addEventListener('click', event => this.clickLink(link, event))
    }
  }

  clickLink (link, event) {
    const slug = link.getAttribute('data-slug')
    const submenu = document.querySelector(`#submenu-${slug}`)

    if (!submenu) {
      return
    }

    event.preventDefault()

    if (!this.element.classList.contains('deluxe-menu-bar--overlay-visible')) {
      this.toggleMenuOverlay() // Force opening the menu on desktop
      this.toggleSubmenu(submenu, link)
    } else {
      const isLevel1 = parseInt(link.getAttribute('data-level'), 10) === 1
      const isSubmenuVisible = document.documentElement.classList.contains('submenu-visible')
      const isExpanded = link.getAttribute('aria-expanded') === 'true'

      if (isLevel1 && isSubmenuVisible && isExpanded) {
        this.toggleMenuOverlay() // Force closing the menu on desktop
        window.clearTimeout(timeout)
        timeout = window.setTimeout(() => this.toggleSubmenu, TIMEOUT_DELAY)
      } else {
        this.toggleSubmenu(submenu, link)
      }
    }
  }

  toggleSubmenu (submenu, link) {
    for (const link of this.linksWithSubmenu) {
      link.setAttribute('aria-expanded', false)
    }

    for (const other of this.submenus) {
      other.classList.remove('deluxe-menu-bar__submenu--visible')
    }

    if (link) {
      link.setAttribute('aria-expanded', true)
    }

    if (submenu) {
      submenu.classList.add('deluxe-menu-bar__submenu--visible')
    }

    const slug = link ? link.getAttribute('data-slug') : ''

    this.element.classList.remove('deluxe-menu-bar--search-visible')
    this.element.classList.remove('deluxe-menu-bar--language-visible')

    this.searchbox.value = ''

    if (slug === 'search' && this.element.classList.contains('deluxe-menu-bar--overlay-visible')) {
      this.element.classList.add('deluxe-menu-bar--search-visible')
      this.searchbox.focus()
    } else if (slug === 'language' && this.element.classList.contains('deluxe-menu-bar--overlay-visible')) {
      this.element.classList.add('deluxe-menu-bar--language-visible')
    } else {
    }

    this.setDocumentClasses()
  }

  initBackground () {
    const closeButton = this.element.querySelector('.button--close.button--menu')

    if (closeButton && this.background) {
      this.background.addEventListener('click', () => closeButton.click())
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.deluxe-menu-bar')) {
    element.instance = element.instance || new DeluxeMenuBarComponent(element)
  }
})
