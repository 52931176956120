
import Component from '../../../scripts/modules/component'
import SwiperWrapper from '../../../scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  spaceBetween: 16,
  loop: true,
  loopAdditionalSlides: 4
}

let timeout

export default class BulletlistCarouselComponent extends Component {
  async init () {
    this.observer = null
    this.container = this.element.querySelector('.swiper-container')

    let swiperOptions = { ...SWIPER_OPTIONS }

    if ([...this.container.querySelectorAll('.swiper-slide')].length < 2) {
      swiperOptions = { ...swiperOptions, ...{ noSwipe: true, navigation: false, pagination: false, loop: false } }
      const buttons = [...this.container.querySelectorAll('.swiper-button-prev, .swiper-button-next')]
      buttons.forEach(button => button.parentNode.removeChild(button))
    }

    this.swiperWrapper = new SwiperWrapper(this.container, swiperOptions, [], {
      slideChange: () => this.onSwiperSlideChange()
    })

    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      this.onSwiperSlideChange()

      window.clearTimeout(timeout)
      timeout = window.setTimeout(() => {
        this.onSwiperSlideChange()
        this.swiperWrapper.swiper.update()
      }, 500)

      // Warkaround for swiper bug; see https://github.com/nolimits4web/Swiper/issues/2099
      if (SWIPER_OPTIONS.loop) {
        for (const button of [...this.element.querySelectorAll('.swiper-button-disabled')]) {
          button.classList.remove('swiper-button-disabled')
        }
      }
    }, 500)
  }

  update () {
    //
  }

  destroy () {
    //
  }

  onSwiperSlideChange () {
    const current = this.swiperWrapper.swiper.slides[this.swiperWrapper.swiper.activeIndex]
    const all = [...Object.values(this.swiperWrapper.swiper.slides)].filter(slide => !!slide.tagName)
    const currentWithDuplicates = [...all.filter(slide => slide.getAttribute('data-swiper-slide-index') === current.getAttribute('data-swiper-slide-index'))]

    for (const slide of currentWithDuplicates) {
      slide.classList.add('swiper-slide--activated')
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.bulletlist-carousel')) {
    element.instance = element.instance || new BulletlistCarouselComponent(element)
  }
})
