
import Component from '../../../scripts/modules/component'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'

export default class ButtonComponent extends Component {
  init () {
    this.element.addEventListener('click', event => {
      fireCustomEvent('button:clicked', { element: this.element, originalEvent: event })
    })
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.button')) {
    element.instance = element.instance || new ButtonComponent(element)
  }
})
