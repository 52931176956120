
// Fire custom event cross-browser
export default function fireCustomEvent (name = '', detail = {}, bubbles = false, cancelable = false) {
  if (!name) {
    return
  }

  let evt

  try {
    evt = new CustomEvent(name, { bubbles: bubbles, cancelable: cancelable, detail: detail })
  } catch (e) { // IE11
    evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(name, bubbles, cancelable, detail)
  }

  window.dispatchEvent(evt)
}
