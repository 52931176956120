
import Component from '../../../scripts/modules/component'
import fireCustomEvent from './../../../vendor/scripts/fabrique/modules/fire-custom-event'

export default class CookieMessageComponent extends Component {
  init () {
    this.element.addEventListener('click', event => {
      event.preventDefault()
      fireCustomEvent('show-cookie-details')
    })
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.cookie-message')) {
    element.instance = element.instance || new CookieMessageComponent(element)
  }
})
