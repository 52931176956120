
import Component from '../../../scripts/modules/component'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'

export default class FormComponent extends Component {
  init () {
    this.element.addEventListener('submit', this.preventMultipleSubmits.bind(this))

    this.element.addEventListener('submit', event => {
      fireCustomEvent('form:submitted', { originalEvent: event, element: this.element })
    })
  }

  update () {
    //
  }

  destroy () {
    //
  }

  preventMultipleSubmits () {
    this.buttonSubmit = this.element.querySelector('button[type="submit"], input[type="submit"]')

    if (this.buttonSubmit) {
      this.buttonSubmit.disabled = true
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.form')) {
    element.instance = element.instance || new FormComponent(element)
  }
})
