
import Component from '../../../scripts/modules/component'

export default class LinkGridComponent extends Component {
  init () {
    this.dropdown = this.element.querySelector('select')
    this.dropdown?.addEventListener('change', () => {
      const url = this.dropdown.options[this.dropdown.selectedIndex].value

      if (url.startsWith('/')) {
        window.location = window.location.origin + url
      } else if (url.startsWith('http')) {
        window.location = url
      }
    })
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.link-grid')) {
    element.instance = element.instance || new LinkGridComponent(element)
  }
})
