
import Component from '../../../scripts/modules/component'
import SwiperWrapper from '../../../scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  spaceBetween: 16,
  breakpointsInverse: true,
  breakpoints: {
    530: { slidesPerView: 1 },
    800: { slidesPerView: 2 },
    1180: { slidesPerView: 3 },
    1480: { slidesPerView: 3 }
  },
  watchOverflow: true
}

export default class PanelCarouselComponent extends Component {
  async init () {
    this.container = this.element.querySelector('.swiper-container')
    this.swiperWrapper = new SwiperWrapper(this.container, SWIPER_OPTIONS, [])
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.panel-carousel')) {
    element.instance = element.instance || new PanelCarouselComponent(element)
  }
})
