
import Component from '../../../scripts/modules/component'
import SwiperWrapper from '../../../scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {}

export default class ArticlePhotoCarouselComponent extends Component {
  async init () {
    this.container = this.element.querySelector('.swiper-container')
    this.swiperWrapper = new SwiperWrapper(this.container, SWIPER_OPTIONS, [])
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.article-photo-carousel')) {
    element.instance = element.instance || new ArticlePhotoCarouselComponent(element)
  }
})
