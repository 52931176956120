import Component from '../../../scripts/modules/component'
import randomHash from './../../../vendor/scripts/fabrique/modules/random-hash'

require('iframe-resizer/js/iframeResizer.min.js')

export default class IFrameComponent extends Component {
  init () {
    const iframe = this.iframe = this.element.querySelector('.iframe__iframe')
    if (! iframe || ! iframe.dataset.src) {
      return
    }

    if (iframe.dataset.src.match('^https?://portal.dynamicsats.com')) { // avoiding the use of URL() (IE11)
      iframe.addEventListener('load', this.dynamicsAtsWorkaround.bind(this, iframe, 0)) // the event itself will be the 3rd argument
    }

    this.startIFrameResizer()
    this.loadIFrame()
  }

  update () {
    //
  }

  destroy () {
    //
  }

  loadIFrame () {
    window.requestAnimationFrame(() => {
      this.iframe.setAttribute('src', this.iframe.getAttribute('data-src'))
      this.iframe.removeAttribute('data-src')
    })
  }

  startIFrameResizer () {
    let timeout

    try {
      window.iFrameResize({ checkOrigin: false, heightCalculationMethod: (document.documentMode && document.documentMode < 11) ? 'max' : 'lowestElement' })
    } catch (e) {
      this.iframe.addEventListener('load', this.resizeIFrame.bind(this))
      window.addEventListener('resize', this.resizeIFrame.bind(this))

      this.resizeIFrame()

      window.clearTimeout(timeout)

      // FIXME what/where's this.onResizeHandler?
      timeout = window.setTimeout(this.onResizeHandler.bind(this), 500)
    }
  }

  resizeIFrame () {
    if (!this.iframe.name) {
      this.iframe.name = randomHash()
    }

    const frame = window.frames[this.iframe.name]

    if (!frame) {
      return
    }

    try {
      this.iframe.style.height = frame.document.documentElement.scrollheight + 'px'
    } catch (e) {
      this.iframe.style.height = '2000px'
    }
  }

   /**
   * Workaround for application pages hosted @ portal.dynamicsats.com
   *
   * These pages fetch a chunk of HTML, containing the actual form + the .contentWindow script
   *   via XHR, and it's injected via jQuery.html() (making the script load)
   */
  dynamicsAtsWorkaround (iframe, iter) {
    if (iter > 5) {  // just a failsafe, or when it's taking too long for some reason (slow connections!)
      window.clearTimeout(this.workaroundTimeoutId)
      iframe.style.height = '76em'
      iframe.removeAttribute('scrolling') // when 76em isn't enough
      return
    }

    if (! iframe.style.height) {
      iframe.removeAttribute('id')
      window.iFrameResize({ heightCalculationMethod: (document.documentMode && document.documentMode < 11) ? 'max' : 'lowestElement' });
      this.workaroundTimeoutId = window.setTimeout(this.dynamicsAtsWorkaround.bind(this, iframe, ++iter), 1000);
    }
  }

}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.iframe')) {
    element.instance = element.instance || new IFrameComponent(element)
  }
})
