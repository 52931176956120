
import Component from '../../../scripts/modules/component'

let timeout

export default class ArticleCodeComponent extends Component {
  async init () {
    this.pre = this.element.querySelector('.article-code__pre')
    this.code = this.element.querySelector('.article-code__code')
    this.language = this.element.getAttribute('data-language') || 'none'

    if (!this.code) {
      return
    }

    this.codeString = this.code.innerHTML
    this.codeString = this.codeString.trimStart()
    this.codeString = this.codeString.trimEnd()
    this.code.innerHTML = this.codeString

    if (!this.codeString) {
      return
    }

    if (!window.Prism) {
      (await import(/* webpackChunkName: "prism-core" */ '../../../scripts/plugins/prism-core')).default
      // await import(/* webpackChunkName: "prism-plugins" */ '../../../scripts/plugins/prism-plugins') // Werken niet, zie https://github.com/PrismJS/prism/issues/1171
      await import(/* webpackChunkName: "prism-languages" */ '../../../scripts/plugins/prism-languages')
      window.Prism.manual = true
    }

    Prism.highlightElement(this.code)
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.article-code')) {
    element.instance = element.instance || new ArticleCodeComponent(element)
  }
})
