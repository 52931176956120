
import Component from '../../../scripts/modules/component'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default class SkinnedContent extends Component {
  init () {
    this.waitForForm().then(() => {
      this.form.addEventListener('submit', event => {
        fireCustomEvent('skinned-content:form-submit', {
          originalEvent: event,
          element: this.element,
          form: this.form
        })
      })
    })
  }

  /**
   * waitForForm
   *
   * Since we load content asynchronously, we don't know when it arrives, therefor we poll
   * until we see it.
   */
  async waitForForm () {
    let t = 100
    this.form = this.element.querySelector('form')
    while (!this.form) {
      await sleep(t)
      this.form = this.element.querySelector('form')
      t *= 2
    }
    return this.form
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.skinned-content')) {
    element.instance = element.instance || new SkinnedContent(element)
  }
})
