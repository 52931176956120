
// Import common stuff
import displayHelloConsoleMessage from './../vendor/scripts/fabrique/modules/console-hello'
import preventTransitionsOnResize from './../vendor/scripts/fabrique/modules/prevent-transitions-on-resize'
import setAllExternalLinksToTargetBlank from './../vendor/scripts/fabrique/modules/external-links-target-blank'

// Main components
import './../components/atoms/background-image/background-image.js'
import './../components/atoms/background-video/background-video.js'
import './../components/atoms/button/button.js'
import './../components/atoms/form-field/form-field.js'
import './../components/atoms/iframe/iframe.js'
import './../components/atoms/picture/picture.js'
import './../components/atoms/share-button/share-button.js'
import './../components/atoms/video-embed/video-embed.js'
import './../components/atoms/video/video.js'
import './../components/molecules/card/card.js'
import './../components/molecules/cookie-message/cookie-message.js'
import './../components/molecules/form/form.js'
import './../components/organisms/action-bar/action-bar.js'
import './../components/organisms/article-code/article-code.js'
import './../components/organisms/article-cookies/article-cookies.js'
import './../components/organisms/article-gist/article-gist.js'
import './../components/organisms/article-photo-carousel/article-photo-carousel.js'
import './../components/organisms/article-quote-cards/article-quote-cards.js'
import './../components/organisms/block-list/block-list.js'
import './../components/organisms/brick-grid/brick-grid.js'
import './../components/organisms/bulletlist-carousel/bulletlist-carousel.js'
import './../components/organisms/card-carousel/card-carousel.js'
import './../components/organisms/card-grid/card-grid.js'
import './../components/organisms/carousel-header/carousel-header.js'
import './../components/organisms/event-carousel/event-carousel.js'
import './../components/organisms/event-grid/event-grid.js'
import './../components/organisms/event-overview-carousel/event-overview-carousel.js'
import './../components/organisms/event-panel-carousel/event-panel-carousel.js'
import './../components/organisms/expert-carousel/expert-carousel.js'
import './../components/organisms/faq-accordeon/faq-accordeon.js'
import './../components/organisms/filter-bar/filter-bar.js'
import './../components/organisms/flipbase/flipbase.js'
import './../components/organisms/link-bar/link-bar.js'
import './../components/organisms/link-grid/link-grid.js'
import './../components/organisms/panel-carousel/panel-carousel.js'
import './../components/organisms/panel-grid/panel-grid.js'
import './../components/organisms/poster-accordeon/poster-accordeon.js'
import './../components/organisms/search-bar/search-bar.js'
import './../components/organisms/skinned-content-deluxe/skinned-content-deluxe.js'
import './../components/organisms/skinned-content/skinned-content.js'
import './../components/organisms/text-header-form/text-header-form.js'
import './../components/structures/cookie-bar/cookie-bar.js'
import './../components/structures/deluxe-menu-bar/deluxe-menu-bar.js'
import './../components/structures/expanded-menu-bar/expanded-menu-bar.js'
import './../components/structures/language-switch-bar/language-switch-bar.js'
import './../components/structures/menu-bar/menu-bar.js'
import './../components/twaddles/flubber/flubber.js'

// Maxlead conversion script
import './modules/maxlead'

// Connexys Iframe enhancement for Maxlead
import './modules/connexys-anti-adblock'

// Intersection observer polyfill - about 20kb raw, but we need it for IE11 & Safari
if (!window.IntersectionObserver) {
  require('intersection-observer')
  window.IntersectionObserver.prototype.POLL_INTERVAL = 250 // Time in milliseconds.
}

// Web animations polyfill - about 50kb raw, so only use if you need it
// require('web-animations-js');

// Import smooth scroll - for IE 11, Chrome < 61, Firefox < 36, Safari
if (!('scrollBehavior' in document.documentElement.style)) {
  require('smoothscroll-polyfill').polyfill()
}

// Set variables from webpack
window.DEBUG = (process.env.NODE_ENV !== 'production')

displayHelloConsoleMessage()
setAllExternalLinksToTargetBlank()
preventTransitionsOnResize()

if (process.env.NODE_ENV !== 'production') {
  // Show Neat debug grid on L hotkey and grid on G hotkey when document is focussed.
  document.addEventListener('keydown', event => {
    if (event.target !== document.body) {
      return
    }

    if (event.key === 'l') {
      document.documentElement.classList.toggle('debug-layout')
    } else if (event.key === 'g') {
      document.documentElement.classList.toggle('debug-grid')
    }

    document.cookie = `debug-layout=${document.documentElement.classList.contains('debug-layout')}; path=/;`
    document.cookie = `debug-grid=${document.documentElement.classList.contains('debug-grid')}; path=/;`
  })

  document.documentElement.classList.toggle('debug-layout', document.cookie.indexOf('debug-layout=true') !== -1)
  document.documentElement.classList.toggle('debug-grid', document.cookie.indexOf('debug-grid=true') !== -1)
}
