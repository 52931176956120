/**
 * Created by LMO on 2019-04-19.
 * (c) Fabrique - Merken, Design & Interactie
 */

import Component from '../modules/component'
import fireCustomEvent from '../../vendor/scripts/fabrique/modules/fire-custom-event'
import throttle from 'lodash/throttle'
import serialize from 'form-serialize'

/**
 * Use this for filter/search components that manage a grid of results.
 */
export default class GridManager extends Component {
  init () {
    this.form = this.element.querySelector('form')

    if (!this.form) {
      return
    }

    this.searchInput = this.element.querySelector('input[type="search"]')
    this.checkboxes = [...this.element.querySelectorAll('input[type="checkbox"]')]
    this.pageInput = this.element.querySelector('[name=page]') || this.element.querySelector('[name=p]')

    this.initEvents()

    if (window.FILTER_BAR_INITIAL) {
      this.update(window.FILTER_BAR_INITIAL)
    }

    this.requestNumber = 0
    this.requestNumberLoaded = 0

    // Used to determine if new data actually changed, to prevent screen flashes
    this.dataHash = '-'
  }

  destroy () {
    //
  }

  initEvents () {
    this.form.addEventListener('change', event => this.onFormChange(event))
    this.form.addEventListener('submit', event => this.onFormSubmit(event))

    if (this.searchInput) {
      this.searchInput.addEventListener(
        'keydown',
        throttle(
          () => setTimeout(
            () => this.onFormSubmit(),
            1),
          400
        )
      )
      this.searchInput.addEventListener('change', throttle(() => this.onFormSubmit(), 400))
    }

    if (this.onShowMoreClick) {
      window.addEventListener('filter-bar:request-load-more', this.onShowMoreClick.bind(this))
    }
  }

  onShowMoreClick (event) {
    event.preventDefault()

    this.pageInput.value = Number(this.pageInput.value) + 1
    this.onFormSubmit()
  }

  onFormChange (event) {
    this.pageInput.value = 1

    this.onFormSubmit(null)
  }

  async onFormSubmit (event) {
    const formData = serialize(this.form)

    fireCustomEvent('filter-bar:load-more-hidden')

    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    const data = await this.search()

    if (data === null) {
      return
    }

    if (window.history && window.history.replaceState) {
      window.history.replaceState({}, 'overview', `?${formData}&json=0`)
    }

    this.update(data)
  }

  async search () {
    const formData = serialize(this.form)
    const url = `${this.form.action}?${formData}`
    const options = {
      method: 'GET',
      mode: 'same-origin',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
      }
    }

    this.requestNumber += 1
    const requestNumber = this.requestNumber

    const response = await fetch(url, options)

    if (!response || !response.ok || !response.json) {
      console.log('Response not OK')
      return null
    }

    if (this.requestNumberLoaded > requestNumber) {
      console.log('Response incorrect')
      return null
    }

    this.requestNumberLoaded = requestNumber

    const data = response.json()

    data.formData = formData

    return data
  }

  update (data) {
    if (data.done || data.final_page) {
      fireCustomEvent('filter-bar:load-more-hidden')
    } else {
      fireCustomEvent('filter-bar:load-more-available')
    }

    if (data.hash && data.hash === this.dataHash) {
      return
    }

    this.dataHash = data.hash

    fireCustomEvent('filter-bar:results', {
      empty: data.start === 0,
      results: data.results || data.items,
    })

    if (data.caption || data.title === '') {
      fireCustomEvent('filter-bar:caption-changed', data.caption)
    }

    if (data.title || data.title === '') {
      fireCustomEvent('filter-bar:title-changed', data.title)
    }

    if (!data.formData) {
      data.formData = serialize(this.form)
    }
    fireCustomEvent('filter-bar:data-updated', {
      data: data
    })
  }
}
