/**
 * Created by LMO on 2019-08-21.
 * (c) Fabrique - Merken, Design & Interactie
 */
import FilterBarObserver from '../../../scripts/components/filter-bar-observer'
import BackgroundImageComponent from '../../atoms/background-image/background-image';

export default class BlockListComponent extends FilterBarObserver {
  onFilterBarResults (event) {
    super.onFilterBarResults(event)

    Array.from(this.elementItemsContainer.querySelectorAll('.background-image')).forEach(element => {
      const isNew = !element.instance
      element.instance = element.instance || new BackgroundImageComponent(element)

      if (isNew) {
        element.instance.loadImage()
        element.instance.disconnectObserver()
      }
    })
  }

  insertNewItem (itemHTML) {
    const newElement = document.createElement('div')
    newElement.setAttribute('class', 'block-list__block-container')
    newElement.innerHTML = itemHTML
    this.elementItemsContainer.appendChild(newElement)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.block-list')) {
    element.instance = element.instance || new BlockListComponent(element)
  }
})
