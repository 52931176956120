
import Component from '../../../scripts/modules/component'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import serialize from 'form-serialize'
import CardComponent from '../../molecules/card/card'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'
import supportsPositionSticky from '../../../vendor/scripts/fabrique/modules/supports-position-sticky'
import GridManager from '../../../scripts/components/grid-manager';

const MIN_HEIGHT = 560;

export default class FilterBarComponent extends GridManager {
  init () {
    this.buttonToggleFilters = this.element.querySelector('.button--toggle-filters')
    this.checkboxes = [...this.element.querySelectorAll('input[type="checkbox"]')]
    this.overlay = this.element.querySelector('.filter-bar__filter-overlay')
    this.ctaButton = this.element.querySelector('.filter-bar__cta')
    this.ctaButtonCounter = this.ctaButton.querySelector('.filter-bar__cta--counter')
    this.formWrapper = this.element.querySelector('.form__wrapper')
    this.scollToElement = document.querySelector('.link--reset')
    this.formElement = this.element.querySelector('.filter-bar__form')
    this.resetButton = this.element.querySelector('[type=reset]')
    this.showCounts = !!this.element.hasAttribute('data-show-counts')
    this.countMap = new Map(this.checkboxes.map(checkbox => [checkbox.id, this.element.querySelector('label[for=' + checkbox.id + ']').children[0]]))
    this.overlayState = null
    this.scrollListener = this.onScroll.bind(this)
    this.debouncedScrollListener = debounce(this.scrollListener, 10, { leading: true, trailing: true })

    if (supportsPositionSticky()) {
      this.formElement.prepend(this.ctaButton)
    } else {
      this.ctaButton.style.display = 'none'
    }

    super.init()
  }

  destroy () {
    //
  }

  initEvents () {
    super.initEvents()

    if (this.buttonToggleFilters) {
      this.buttonToggleFilters.addEventListener('click', event => this.toggleFilterOverlay(event))
    }

    for (const checkbox of this.checkboxes) {
      checkbox.addEventListener('change', event => this.onCheckboxChange(event))
    }

    this.ctaButton.addEventListener('click', throttle((event) => setTimeout(() => {
      event.preventDefault()
      if (!this.scollToElement) {
        return
      }

      this.scollToElement.scrollIntoView({ behavior: 'smooth' })
    }, 1)))

    this.resetButton.addEventListener('click', throttle(() => setTimeout(() => this.onFormSubmit(), 1)))
  }

  toggleFilterOverlay (event) {
    event.preventDefault()
    this.overlay.classList.toggle('filter-bar__filter-overlay--visible')
    this.overlayState = this.overlay.classList.contains('filter-bar__filter-overlay--visible') ? 'open' : 'closed'
    if (this.overlayState === 'open') {
      window.addEventListener('scroll', this.debouncedScrollListener)
      window.addEventListener('resize', this.debouncedScrollListener)
      this.showCtaButton()
      if (window.innerHeight <= MIN_HEIGHT) {
        this.ctaButton.classList.add('filter-bar__cta--initially-hidden')
      }
    } else {
      window.removeEventListener('scroll', this.debouncedScrollListener)
      window.removeEventListener('resize', this.debouncedScrollListener)
      this.hideCtaButton()
    }

    fireCustomEvent('filter-bar:toggled', {
      newState: this.overlayState
    })

    this.prevOverlayState = this.overlayState
  }

  onCheckboxChange (event) {
    const children = [...event.target.parentNode.querySelectorAll('.filter-bar__filter-list .filter-bar__checkbox')]
    if (!children.length) {
      return
    }

    if (!event.target.checked) {
      for (const child of children) {
        child.checked = false
        child.classList.remove('checked')
      }
    }

    this.updateCtaButtonText()
  }

  updateCtaButtonText () {
    this.totalSelected = 0
    const selectedCategories = [...this.element.querySelectorAll('.filter-bar__filter--level-1')]
    for (const selectedCategory of selectedCategories) {
      if (selectedCategory.querySelector('input').checked) {
        const count = selectedCategory.querySelector('.filter-bar__checkbox-label-span');
        this.totalSelected += parseInt(count.dataset.count)
      }
    }

    this.ctaButtonCounter.textContent = this.totalSelected

  }

  showCtaButton () {
    this.ctaButton.classList.add('filter-bar__cta--sticky')
    this.formWrapper.classList.toggle('form__wrapper--visible')
  }

  hideCtaButton () {
    this.ctaButton.classList.remove('filter-bar__cta--sticky')
    this.formWrapper.classList.toggle('form__wrapper--visible')
  }

  onScroll () {
    window.requestAnimationFrame(() => {
      if (this.overlayState === 'open') {
        if (window.scrollY > 200) {
          this.ctaButton.classList.remove('filter-bar__cta--initially-hidden')
        } else {
          if (window.innerHeight <= MIN_HEIGHT) {
            this.ctaButton.classList.add('filter-bar__cta--initially-hidden')
          } else {
            this.ctaButton.classList.remove('filter-bar__cta--initially-hidden')
          }
        }
      }
    })
  }

  update (data) {
    if (data.hash && data.hash === this.dataHash) {
      return
    }

    super.update(data)
    if (this.showCounts) {
      const unused = new Set(this.countMap.keys())
      const hasActiveChildren = new Set()

      Object.entries(data.filters).forEach(([filter, subfilter]) => {
        Object.entries(subfilter).forEach(([id, count]) => {
          const key = `id_${filter}_${id}`
          const value = this.countMap.get(key)

          if (value) {
            const is2ndLevel = value.parentNode.parentNode.classList.contains('filter-bar__filters--level-2')

            if (is2ndLevel) {
              hasActiveChildren.add(value.parentNode.parentNode.parentNode.parentNode)
            }

            value.setAttribute('data-count', count)

            value.parentNode.parentNode.style.order = '' + (1000 - count) // Order by count descending
            value.parentNode.parentNode.style.display = '' // Clear any custom display styles (effect: display the node)
            unused.delete(key)
          }
        })
      })

      unused.forEach(key => {
        const labelSpan = this.countMap.get(key)

        labelSpan.setAttribute('data-count', '0')

        // hide nodes without count, if they are not manually checked
        const node = labelSpan.parentNode.parentNode
        node.style.order = '1000'

        if (!document.getElementById(labelSpan.parentNode.getAttribute('for')).checked) {
          node.style.display = 'none'
        }
      })

      // here be dragons
      Array.from(this.element.querySelectorAll('.filter-bar__filter--level-1')).forEach(filterElement => {
        if (hasActiveChildren.has(filterElement)) {
          filterElement.querySelector('.filter-bar__checkbox-label-icon').style.display = ''
        } else {
          const icon = filterElement.querySelector('.filter-bar__checkbox-label-icon')

          if (icon) {
            icon.style.display = 'none'
          }
        }
      })
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.filter-bar')) {
    element.instance = element.instance || new FilterBarComponent(element)
  }
})
