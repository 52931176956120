
// Prevents transitions on resize
// Changing transition properties between breakpoints otherwise 'resets' the animation, so that a transition flash is visible.
// Needs the following css: html.prevent-transitions * { transition: none !important; }
const DELAY = 10

export default function preventTransitionsOnResize () {
  let timeout

  window.addEventListener('resize', () => {
    document.documentElement.classList.add('prevent-transitions')

    timeout = window.clearTimeout(timeout)
    timeout = window.setTimeout(() => {
      document.documentElement.classList.remove('prevent-transitions')
    }, DELAY)
  })
}
