
import Component from '../../../scripts/modules/component'

export default class VideoComponent extends Component {
  init () {
    this.container = this.element.querySelector('.video__container')
    this.hitTarget = this.element.querySelector('.video__hit-target')
    this.video = this.element.querySelector('.video__video')
    this.autoplay = (this.element.getAttribute('data-autoplay') !== null)

    if (!this.container || !this.hitTarget || !this.video) {
      return
    }

    this.initVideo()
  }

  update () {
    //
  }

  destroy () {
    //
  }

  initVideo () {
    this.hitTarget.addEventListener('click', this.onHitTargetClick.bind(this))
    this.video.addEventListener('click', this.onHitTargetClick.bind(this))

    if (this.autoplay) {
      this.element.classList.remove('video--paused')
      this.element.classList.add('video--playing')
    }
  }

  onHitTargetClick (event) {
    event.preventDefault()

    if (this.video.currentTime === 0 || this.video.paused || this.video.ended) {
      this.element.classList.remove('video--paused')
      this.element.classList.add('video--playing')
      this.video.play()
    } else {
      this.element.classList.remove('video--playing')
      this.element.classList.add('video--paused')
      this.video.pause()
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.video')) {
    element.instance = element.instance || new VideoComponent(element)
  }
})
