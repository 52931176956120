
import required from '../../vendor/scripts/fabrique/modules/required'
import isFunction from 'lodash/isFunction'

const DEFAULT_SWIPER_OPTIONS = {
  autoPlay: false,
  controlBy: 'container',
  direction: 'horizontal',
  effect: 'slide',
  grabCursor: true,
  initialSlide: 0,
  loop: false,
  loopAdditionalSlides: 0,
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
  pagination: { el: '.swiper-pagination', type: 'bullets', bulletElement: 'span', clickable: true },
  paginationClickable: true,
  resistanceRatio: 0,
  roundLengths: true,
  slidesPerView: 1,
  spaceBetween: 0,
  threshold: 5,
  touchRatio: 1,
  watchOverflow: false
}

const DEFAULT_INTERSECT_HOOKS = [
  {
    querySelector: '.background-image',
    callback: (change, element) => {
      if (change.isIntersecting && element.forceLoad && isFunction(element.forceLoad)) {
        element.forceLoad()
      }
    }
  },

  {
    querySelector: '.background-video',
    callback: (change, element) => {
      if (change.isIntersecting && element.forceLoad && isFunction(element.forceLoad)) {
        element.forceLoad()
      }

      if (change.isIntersecting && element.forcePlay && isFunction(element.forcePlay)) {
        element.forcePlay()
      } else if (!change.isIntersecting && element.forcePause && isFunction(element.forcePause)) {
        element.forcePause()
      }
    }
  }
]

export const STRATEGY_DEFAULT = 'default'
export const STRATEGY_JUST = 'just'

export default class SwiperWrapper {
  constructor (element = required(), options = {}, intersectHooks = [], swiperEvents = {}, strategy=STRATEGY_DEFAULT) {
    this.element = element
    if (strategy === STRATEGY_DEFAULT) {
      this.options = Object.assign({}, DEFAULT_SWIPER_OPTIONS, options)
    } else if (strategy === STRATEGY_JUST) {
      this.options = options
    }

    if (options.thumbs) {
      this.options = options
    }
    this.intersectHooks = [...DEFAULT_INTERSECT_HOOKS, ...intersectHooks]
    this.swiperEvents = swiperEvents
    this.swiper = null
    this.observer = null

    this.init()

    return this
  }

  async init () {
    const Swiper = await this.loadSwiper()

    this.swiper = new Swiper(this.element, this.options)

    if (this.swiperEvents) {
      Object.entries(this.swiperEvents).forEach(item => this.swiper.on(item[0], item[1]))
    }

    window.requestAnimationFrame(() => this.swiper.update())

    if (this.intersectHooks.length) {
      this.observer = null
      // Set observers on next paint (otherwise it'll cause a forced layout reflow)
      window.setTimeout(() => {
        for (const hook of this.intersectHooks) {
          const elements = [...this.element.querySelectorAll(hook.querySelector)]

          for (const element of elements) {
            if (element && element.disconnectObservers && isFunction(element.disconnectObservers)) {
              element.disconnectObservers() // Remove original observer
            }

            window.requestAnimationFrame(() => {
              hook.callback({ isIntersecting: false }, element)
            })
          }
        }

        window.requestAnimationFrame(() => this.attachObserver())
      }, 350)
    }
  }

  update () {
    //
  }

  destroy () {
    this.observer = null
  }

  async loadSwiper () {
    const Swiper = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/components/core/core-class')).default
    const Navigation = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/components/navigation/navigation')).default
    const Pagination = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/components/pagination/pagination')).default
    const Browser = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/modules/browser/browser')).default
    const Device = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/modules/device/device')).default
    const Observer = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/modules/observer/observer')).default
    const Resize = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/modules/resize/resize')).default
    const Support = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/modules/support/support')).default
    const Thumbs = (await import(/* webpackChunkName: "swiper" */ 'swiper/src/components/thumbs/thumbs')).default

    if (typeof Swiper.use === 'undefined') {
      Swiper.use = Swiper.Class.use
      Swiper.installModule = Swiper.Class.installModule
    }

    Swiper.use([Device, Support, Browser, Resize, Observer, Navigation, Pagination, Thumbs])
    return Swiper
  }

  attachObserver () {
    if (this.observer) {
      this.disconnectObserver()
    }

    // Start halfway outside the viewport
    this.observer = new window.IntersectionObserver(this.onIntersect.bind(this), {
      root: this.element, rootMargin: '-2px -2px'
    })

    const slides = Object.values(this.swiper.slides).filter(value => value.classList)

    for (const slide of slides) {
      this.observer.observe(slide)
    }
  }

  disconnectObserver () {
    if (!this.observer) {
      return
    }

    this.observer.disconnect()
    this.observer = null
  }

  onIntersect (changes = []) {
    for (const change of changes) {
      for (const hook of this.intersectHooks) {
        const element = change.target.querySelector(hook.querySelector)

        if (element && hook.callback && isFunction(hook.callback)) {
          hook.callback(change, element)
        }
      }
    }
  }
}
