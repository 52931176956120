/**
 * Anti adblock mechanism for Connexys iFrames.
 */
import cookie from '../../vendor/scripts/fabrique/modules/cookie'


function initialize() {
  waitForDocumentLoaded(() => !!getConnexysIframe()).then(() => {
    injectClientIdInIframe(getConnexysIframe())
  })
}

/**
 * Check if a constraint is passed and test again after load events otherwise.
 *
 * @param checkConstraint
 * @returns {Promise}
 */
function waitForDocumentLoaded(checkConstraint) {
  return new Promise((resolve, reject) => {
    const events = ['DOMContentLoaded', 'load'];
    const onLoadedListener = () => {
      // Make sure this is only activated once
      const onPassed = singleTimeActivator(() => {
        resolve();
        events.forEach(event => {
          window.removeEventListener(event, onLoadedListener);
        });
      })
      if (checkConstraint()) {
        onPassed()
      }
    }
    if (checkConstraint()) {
      resolve();
    } else {
      events.forEach(event => {
        window.addEventListener(event, onLoadedListener);
      });
    }
  })
}


/**
 * Make sure a certain function is activated only once
 * @param onActivate
 * @returns {function(...[*]=)}
 */
function singleTimeActivator(onActivate) {
  let activated = false;
  return () => {
    if (!activated) {
      activated = true;
      onActivate()
    }
  }
}


/**
 * Find the connexys iframe and return it if it was found.
 */
function getConnexysIframe() {
  return document.querySelector('iframe[src*="connexys.nl"]')
}


/**
 * Inject a client id into an iframe.
 *
 * @param iframe
 */
function injectClientIdInIframe(iframe) {
  iframe.src = addQueryParameter(iframe.src, 'p_client_id', getClientID())
}


/**
 * Add a query parameter to a URL.
 * @param url
 * @param name
 * @param value
 * @returns {string}
 */
function addQueryParameter(url, name, value) {
  const [pre_query, after_query] = url.split('?')
  const [ename, evalue] = [name, value].map(encodeURIComponent)
  const eparam = `${ename}=${evalue}`
  if (!after_query) {
    return `${pre_query}?${eparam}`
  } else {
    const [pre_hash, after_hash] = after_query.split('#')
    const base = `${pre_query}?${pre_hash}&${eparam}`
    return after_hash ? `${base}#${after_hash}` : base
  }
}


/**
 * Get a client ID to inject.
 * @returns {*}
 */
function getClientID() {
  return hasGACookie() ? getClientIdFromGACookie() : generateClientID()
}


/**
 * Check if we can rely on Google Analytics presence.
 * @returns {boolean}
 */
function hasGACookie() {
  return !!cookie('_ga')
}


/**
 * Get a client id from google analytics.
 * @returns {*}
 */
function getClientIdFromGACookie() {
  return cookie('_ga').split('.').slice(2).join('.')
}


/**
 * Generate a custom client id, as alternative to one from Google Analytics
 * @returns {string}
 */
function generateClientID() {
  return `adblock-${Math.floor(Math.random() * 1000 * 1000 * 10) + 1000 * 1000 * 10}`
}

initialize()
