
// Gives a nice Fabrique message in the console
// You can leave the message, color or background empty for the defaults.
export default function displayHelloConsoleMessage (message = 'Made with ♥ by Fabrique', color = '#000', backgroundColor = '#fff', heartColor = '#f00', heartBackgroundColor = '#fff') {
  if (window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1 || window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    const args = [
      `\n %c` + message.replace(`♥`, `%c♥%c`) + ` \n`,
      `font: 16px/1.5em serif; background-color: ${backgroundColor}; color: ${color};`,
      `font: 13px/1.5em serif; background-color: ${heartBackgroundColor}; color: ${heartColor};`,
      `font: 16px/1.5em serif; background-color: ${backgroundColor}; color: ${color};`
    ]

    window.console.log.apply(console, args)
  } else {
    // window.console.log(message);
  }
}
