/**
 * Created by LMO on 2019-08-19.
 * (c) Fabrique - Merken, Design & Interactie
 */
import Component from '../modules/component'
import fireCustomEvent from '../../vendor/scripts/fabrique/modules/fire-custom-event'

export default class FilterBarObserver extends Component {
  init () {
    if (!this.element.hasAttribute('data-use-filter-bar')) {
      return
    }

    this.elementLoadMore = this.elementLoadMore || this.element.querySelector('[data-role=grid-load-more]')
    this.elementTitle = this.elementTitle || this.element.querySelector('[data-role=grid-title]')
    this.elementCaption = this.elementCaption || this.element.querySelector('[data-role=grid-caption]')
    this.elementItemsContainer = this.elementItemsContainer || this.element.querySelector('[data-role=card-container]')

    // Bind filter bar events to functions
    window.addEventListener('filter-bar:load-more-available', this.onFilterBarShowLoadMore.bind(this))
    window.addEventListener('filter-bar:load-more-hidden', this.onFilterBarHideLoadMore.bind(this))
    window.addEventListener('filter-bar:title-changed', this.onFilterBarTitleChanged.bind(this))
    window.addEventListener('filter-bar:caption-changed', this.onFilterBarCaptionChanged.bind(this))
    window.addEventListener('filter-bar:results', this.onFilterBarResults.bind(this))

    if (this.elementLoadMore) {
      // Send a load more event to the filter bar when the load more is clicked
      this.elementLoadMore.addEventListener('click', this.sendLoadMoreEvent.bind(this))
    }
  }

  onFilterBarTitleChanged (event) {
    this.elementTitle.innerHTML = event.detail
  }

  onFilterBarCaptionChanged (event) {
    this.elementCaption.innerHTML = event.detail
  }

  onFilterBarResults (event) {
    if (event.detail.empty) {
      this.elementItemsContainer.innerHTML = ''
    }

    Array.from(event.detail.results).forEach(item => this.insertNewItem(item))
  }

  onFilterBarShowLoadMore (event) {
    this.elementLoadMore.classList.remove('link--hidden')
  }

  onFilterBarHideLoadMore (event) {
    this.elementLoadMore.classList.add('link--hidden')
  }

  insertNewItem (itemHTML) {
    this.elementItemsContainer.innerHTML += itemHTML
  }

  sendLoadMoreEvent (event) {
    event.preventDefault()

    console.log('sending load more event')

    fireCustomEvent('filter-bar:request-load-more')
  }
}
