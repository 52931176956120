import Component from '../../../scripts/modules/component'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default class SkinnedContentDeluxe extends Component {
  init() {
    this.waitForForm().then(() => {
      this.form.addEventListener('submit', event => {
        fireCustomEvent('skinned-content:form-submit', {
          originalEvent: event,
          element: this.element,
          form: this.form
        })
      })
    })
    this.scrollButton = this.element.querySelector('.button');

    if (!this.scrollButton) {
      return;
    }
    this.scrollButton.addEventListener('click', event => this.onClick(event));
  }

  onClick(event) {
    event.preventDefault();

    this.target = document.querySelector('.skinned-content-deluxe__form-wrapper');

    if (!this.target) {
      // Fallback - If we don't have a target, assume that we want to scroll exactly one screen height from the top.
      window.scroll({ top: window.innerHeight, behavior: 'smooth' });
    } else {
      // Regular behavior - Scroll to the main element.
      this.target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * waitForForm
   *
   * Since we load content asynchronously, we don't know when it arrives, therefor we poll
   * until we see it.
   */
  async waitForForm() {
    let t = 100
    this.form = this.element.querySelector('form')
    while (!this.form) {
      await sleep(t)
      this.form = this.element.querySelector('form')
      t *= 2
    }
    return this.form
  }

  update() {
    //
  }

  destroy() {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.skinned-content-deluxe')) {
    element.instance = element.instance || new SkinnedContentDeluxe(element)
  }
})
