
import Component from '../../../scripts/modules/component'
import insertScript from './../../../vendor/scripts/fabrique/modules/insert-script'
import randomHash from './../../../vendor/scripts/fabrique/modules/random-hash'

export default class VideoEmbedComponent extends Component {
  init () {
    this.container = this.element.querySelector('.video-embed__container')
    this.hitTarget = this.element.querySelector('.video-embed__hit-target')
    this.autoplay = (this.element.getAttribute('data-autoplay') !== null)

    if (!this.container || !this.hitTarget) {
      return
    }

    this.videoId = this.hitTarget.getAttribute('data-id')
    this.videoType = this.hitTarget.getAttribute('data-type')

    if (!this.videoId || !this.videoType) {
      return
    }

    this.iframeId = `player-${randomHash()}`
    this.player = null

    if (typeof Cookiebot == 'undefined' || !Cookiebot || !Cookiebot.consent.marketing) {
      this.element.classList.add('video-embed--cookies-not-accepted')
      window.addEventListener('CookiebotOnAccept', () => this.initVideo())
      return
    }

    this.initVideo()
  }

  update () {
    //
  }

  destroy () {
    //
  }

  initVideo () {
    if (typeof Cookiebot == 'undefined' || !Cookiebot || !Cookiebot.consent.marketing) {
      return
    }

    window.removeEventListener('CookiebotOnAccept', () => this.initVideo())

    this.element.classList.remove('video-embed--cookies-not-accepted')
    this.hitTarget.addEventListener('click', this.onHitTargetClick.bind(this))

    if (this.autoplay) {
      window.setTimeout(() => this.hitTarget.click(), 150) // Arbitrary timeout to make sure the wrapper styling is painted first.
    }
  }

  onHitTargetClick (event) {
    event.preventDefault()

    if (this.videoType === 'vimeo') {
      insertScript('vimeo-api-script', 'https://player.vimeo.com/api/player.js', this.insertVimeoContainer.bind(this))
    } else if (this.videoType === 'youtube') {
      insertScript('youtube-api-script', 'https://youtube.com/iframe_api', this.insertYouTubeContainer.bind(this))
    } else if (this.videoType === 'vimeolive') {
      insertScript('vimeo-api-script', 'https://player.vimeo.com/api/player.js', this.insertVimeoContainer.bind(this))
    } else {
      throw `VideoEmbed #${this.videoId}: Video type "${this.videoType}" not supported`
    }
  }

  async insertChatContainer () {
    const iframe = document.createElement('iframe')
    iframe.setAttribute('src', `https://vimeo.com/live-chat/${this.videoId}`)
    iframe.setAttribute('class', 'video-embed__chat')
    const onLoaded = () => {
      this.element.classList.add('video-embed--chat-loaded')
    }
    iframe.addEventListener('load', onLoaded);
    this.element.querySelector('.video-embed__chat-container').appendChild(iframe)
  }

  /* jshint ignore:start */
  async insertVimeoContainer () {
    if (!window.Vimeo) {
      throw 'Vimeo API not loaded correctly.'
    }

    this.vimeoOptions = {
      id: this.videoId, // can be either the ID or URL to the video
      autopause: true,
      autoplay: true,
      background: false, // Hide controls and autoplay
      byline: false, // Show byline
      color: 'ffffff',
      // height: 0,
      loop: false,
      // maxheight: 0,
      // maxwidth: 0,
      muted: false,
      playsinline: false, // Set to true to play inline on some mobile devices
      portrait: false, // Show the video portrait
      speed: false, // Show speed controls
      title: false, // Show title
      transparent: true
      // width: 0,
    }

    this.container.removeChild(this.hitTarget)
    this.player = new window.Vimeo.Player(this.container, this.vimeoOptions)

    if (this.videoType === 'vimeolive') {
      this.insertChatContainer()
    }

    if (window.dataLayer) {
      window.dataLayer.push({ event: 'video.loaded' })
    }
  }
  /* jshint ignore:end */

  insertYouTubeContainer () {
    const div = document.createElement('div')

    div.id = this.iframeId
    div.className = 'video-embed__iframe'

    this.hitTarget.parentNode.replaceChild(div, this.hitTarget)
    this.hitTarget = null

    const afterLoad = () => {
      this.player = new window.YT.Player(this.iframeId, {
        width: 640,
        height: 360,
        videoId: this.videoId,
        playerVars: { autoplay: 1, hd: 1, showinfo: 0, modestbranding: 1, iv_load_policy: 3, rel: 0, origin: `${window.location.protocol}//${window.location.host}` },
        events: {
          onReady: () => {
            this.player.setPlaybackQuality('hd1080')

            if (this.element.getAttribute('data-autoplay') !== null) {
              this.player.playVideo()
            }

            if (window.dataLayer) {
              window.dataLayer.push({ event: 'video.loaded' })
            }
          }
        }
      })

      window.youtubeLoaded = true
    }

    if (!window.youtubeLoaded) {
      window.onYouTubeIframeAPIReady = afterLoad
    } else {
      afterLoad()
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.video-embed')) {
    element.instance = element.instance || new VideoEmbedComponent(element)
  }
})
