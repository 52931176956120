
import Component from '../../../scripts/modules/component'

export default class FlubberComponent extends Component {
  init () {
    //
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.flubber')) {
    element.instance = element.instance || new FlubberComponent(element)
  }
})
