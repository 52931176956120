
import Component from '../../../scripts/modules/component'
import fireCustomEvent from './../../../vendor/scripts/fabrique/modules/fire-custom-event'

const COOKIE_NAME = 'cookies-accepted-new'
const EVENT_NAME = 'cookies-accepted'

function getCookie (name) {
  const parts = `; ${document.cookie}`.split(`; ${name}=`)
  return parts.length < 2 ? undefined : parts.pop().split(';').shift()
}

function setCookie (name, value, expiryDays, domain, path, secure) {
  const expiryDate = new Date(new Date().setDate(new Date().getDate() + (expiryDays || 365))).toUTCString()
  const cookie = [`${name}=${value}`]

  cookie.push(`expires=${expiryDate}`)
  cookie.push(`path=${path || '/'}`)

  if (domain) {
    cookie.push(`domain=${domain}`)
  }

  if (secure) {
    cookie.push('secure')
  }

  cookie.push(`SameSite=Lax`)

  document.cookie = cookie.join(';')
}

export default class CookieBarComponent extends Component {
  init () {
    this.bar = this.element.querySelector('.cookie-bar__bar')
    this.details = this.element.querySelector('.cookie-bar__details')

    this.checkboxes = [...this.element.querySelectorAll('input[type="checkbox"]')]

    this.buttonAccept = this.element.querySelector('.button--accept')
    this.buttonReject = this.element.querySelector('.button--reject')
    this.buttonSave = this.element.querySelector('.button--save')
    this.buttonAcceptAll = this.element.querySelector('.button--accept-all')
    this.linkDetails = this.element.querySelector('.link--details')
    this.categoryTextItems = [...this.element.querySelectorAll('.cookie-bar__category-text')]

    this.measureElement = null

    this.addEventListeners()
    this.initConsentLevel()

    window.addEventListener('resize', event => this.onWindowResizeHandler(event))
    this.onWindowResizeHandler()

    // Set global so that it's available for MaxLead
    window.GetConsentLevel = this.getCurrentConsentLevel.bind(this)
  }

  sendGTMEvent (name = '', extraData = {}) {
    window.dataLayer.push({ event: `cookie.consent.system.events.on${name}`, modelConsent: this.modelConsent, levelConsent: this.consentLevel, userConsented: this.userConsented, variantBanner: this.variantBanner })
  }

  initConsentLevel () {
    window.dataLayer = window.dataLayer || [] // Prepare GTM

    this.selectedCategories = []
    this.consentLevel = 0
    this.previousConsentLevel = 0
    this.userConsented = 0
    this.variantBanner = undefined
    this.modelConsent = 'opt-in'

    this.getConsentLevel()
    this.setCategories(this.selectedCategories)
    this.sendGTMEvent('initialise')

    if (!this.selectedCategories.length) {
      this.sendGTMEvent('popupopen')
      this.showBar()
    }
  }

  getCurrentConsentLevel() {
    return this.consentLevel
  }

  determineConsentLevel () {
    if (!this.selectedCategories.length) {
      this.consentLevel = 99
    } else if (this.selectedCategories.indexOf('advertising') !== -1 && this.selectedCategories.indexOf('embeds') !== -1) {
      this.consentLevel = 1
    } else if (this.selectedCategories.indexOf('advertising') !== -1) {
      this.consentLevel = 5
    } else if (this.selectedCategories.indexOf('embeds') !== -1) {
      this.consentLevel = 10
    } else {
      this.consentLevel = 20
    }
  }

  getConsentLevel () {
    const cookie = (getCookie(COOKIE_NAME) || '').trim()

    if (!cookie) {
      this.selectedCategories = []
      this.userConsented = 0
      this.determineConsentLevel()
    } else {
      this.selectedCategories = cookie.split(',')
      this.userConsented = 1
      this.determineConsentLevel()
    }
  }

  setConsentLevel () {
    const categories = this.getSelectedCategories()

    this.previousConsentLevel = this.consentLevel
    this.selectedCategories = categories
    this.userConsented = 1
    this.determineConsentLevel()

    setCookie(COOKIE_NAME, categories.join(','))
    fireCustomEvent(EVENT_NAME)
    this.sendGTMEvent('statuschange', { previouslevelConsent: this.previousConsentLevel })
  }

  addEventListeners () {
    window.addEventListener('show-cookie-bar', () => this.showBar())
    window.addEventListener('show-cookie-details', () => this.showDetails())
    window.addEventListener('clear-cookie-settings', () => this.deleteSelection())

    this.buttonAccept?.addEventListener('click', event => this.onButtonChangeClick(event, 'accept'))
    this.buttonReject?.addEventListener('click', event => this.onButtonChangeClick(event, 'reject'))
    this.buttonAcceptAll?.addEventListener('click', event => this.onButtonChangeClick(event, 'accept'))

    this.buttonSave?.addEventListener('click', event => this.onButtonChangeClick(event))
    this.linkDetails?.addEventListener('click', event => this.onLinkDetailsClick(event))

    for (const item of this.categoryTextItems) {
      item.addEventListener('click', event => this.onCategoryTextItemClick(event))
    }
  }

  onButtonChangeClick (event, status) {
    event.preventDefault()

    if (status === 'accept') {
      this.setCategories(this.getAllCategories())
    } else if (status === 'reject') {
      this.setCategories(this.getDefaultCategory())
    }

    this.setConsentLevel()
    this.hideDetails()
    this.hideBar()

    this.sendGTMEvent('popupclose')
  }

  onLinkDetailsClick (event) {
    event.preventDefault()

    this.showDetails()
    this.hideBar()
  }

  deleteSelection () {
    const categories = this.getDefaultCategory()

    this.previousConsentLevel = this.consentLevel
    this.setCategories(categories)

    setCookie(COOKIE_NAME, '', -365)
    fireCustomEvent(EVENT_NAME)
    this.sendGTMEvent('revocechoice', { previouslevelConsent: this.previousConsentLevel })

    window.setTimeout(() => this.showBar(), 100)
  }

  showBar () {
    this.onWindowResizeHandler()
    window.requestAnimationFrame(() => this.bar.classList.add('cookie-bar__bar--visible'))
    this.hideDetails()
  }

  hideBar () {
    window.requestAnimationFrame(() => this.bar.classList.remove('cookie-bar__bar--visible'))
    this.onWindowResizeHandler()
  }

  showDetails () {
    this.onWindowResizeHandler()
    window.requestAnimationFrame(() => this.details.classList.add('cookie-bar__details--visible'))

    // Set embeds checkbox checked by default
    this.getRecommendedCategories().forEach(category => this.checkboxes.filter(checkbox => checkbox.name === category).forEach(eligibleCheckbox => eligibleCheckbox.checked = true ))

    this.hideBar()
  }

  hideDetails () {
    window.requestAnimationFrame(() => this.details.classList.remove('cookie-bar__details--visible'))
    this.onWindowResizeHandler()
  }

  getSelectedCategories () {
    return this.checkboxes.filter(checkbox => checkbox.checked).map(checkbox => checkbox.name) || []
  }

  getAllCategories () {
    return this.checkboxes.map(checkbox => checkbox.name) || []
  }

  getRecommendedCategories() {
    return ['embeds']
  }

  getDefaultCategory () {
    return this.checkboxes.filter(checkbox => checkbox.disabled).map(checkbox => checkbox.name) || []
  }

  setCategories (categories = []) {
    this.checkboxes.filter(checkbox => !checkbox.disabled).forEach(checkbox => checkbox.checked = false)
    categories.forEach(category => this.checkboxes.filter(checkbox => checkbox.name === category).forEach(checkbox => checkbox.checked = true))
  }

  onCategoryTextItemClick () {
    const forString = event.target.getAttribute('data-for')

    if (!forString) {
      return
    }

    const target = document.getElementById(forString)

    if (!target) {
      return
    }

    target.click()
  }

  onWindowResizeHandler () {
    window.requestAnimationFrame(() => {
      const source = this.element.querySelector('.cookie-bar__details--visible, .cookie-bar__bar--visible')
      const padding = source ? source.offsetHeight + 'px' : 0
      const overlay = document.querySelector('.deluxe-menu-bar__overlay')
      const menuBar = document.querySelector('.deluxe-menu-bar__bar')

      if (padding) {
        if (overlay) {
          overlay.style.top = padding
          overlay.style.height = `calc(100vh - ${padding})`
        }

        if (menuBar) {
          menuBar.style.top = padding
        }

        // document.documentElement.style.transform = `translate3d(0, ${padding}, 0)`
        document.body.style.paddingTop = padding
      } else {
        if (overlay) {
          overlay.removeAttribute('style')
        }

        if (menuBar) {
          menuBar.removeAttribute('style')
        }

        document.body.removeAttribute('style')
      }

      // Comment out this line of code due to buggy scrolling behavior
      // window.requestAnimationFrame(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }))
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.cookie-bar')) {
    element.instance = element.instance || new CookieBarComponent(element)
  }
})
