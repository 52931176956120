
import Component from '../../../scripts/modules/component'
import SwiperWrapper, {STRATEGY_JUST} from '../../../scripts/modules/swiper-wrapper'

const LOGO_SWIPER_OPTIONS = {
  slidesPerView: 'auto',
  watchSlidesProgress: true,
  watchSlidesVisibility: true,
  threshold: 50,
}
const CARD_SWIPER_OPTIONS = {
}

export default class ArticleQuoteCardsComponent extends Component {
  init () {
    this.logoSwiperContainer = this.element.querySelector('.article-quote-cards__logo-swiper')
    this.cardSwiperContainer = this.element.querySelector('.article-quote-cards__card-swiper')
    this.logoSwiperWrapper = new SwiperWrapper(this.logoSwiperContainer, LOGO_SWIPER_OPTIONS, [], {}, STRATEGY_JUST)
    this.logoSwiperWrapper.init().then(() => {
      this.cardSwiperWrapper = new SwiperWrapper(this.cardSwiperContainer, { ...CARD_SWIPER_OPTIONS, thumbs: { swiper: this.logoSwiperWrapper.swiper } }, [], {}, STRATEGY_JUST)
    })
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.article-quote-cards')) {
    element.instance = element.instance || new ArticleQuoteCardsComponent(element)
  }
})
