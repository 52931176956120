
import GridManager from '../../../scripts/components/grid-manager'

export default class LinkBarComponent extends GridManager {
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.link-bar')) {
    element.instance = element.instance || new LinkBarComponent(element)
  }
})
