
import Component from '../../../scripts/modules/component'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'

export default class CardComponent extends Component {
  init () {
    this.cardAnchor = this.element.querySelector('.card__anchor')
    this.cardAnchor?.addEventListener('click', event => {
      fireCustomEvent('card:clicked', { element: this.element })
    })
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.card')) {
    element.instance = element.instance || new CardComponent(element)
  }
})
