/**
 * Created by LMO on 2019-08-19.
 * (c) Fabrique - Merken, Design & Interactie
 */
import FilterBarObserver from '../../../scripts/components/filter-bar-observer'

export default class CardGridComponent extends FilterBarObserver {
  init () {
    this.vacancyResultsKey = this.element.getAttribute('data-vacancy-results-key')

    this.elementLinkTitle = this.element.querySelector('[data-role=grid-link-title]')
    this.elementLink = this.element.querySelector('[data-role=grid-link]')

    super.init()

    window.addEventListener('filter-bar:vacancy-results', this.onFilterBarVacancyResults.bind(this))
  }

  onFilterBarResults (event) {
    if (!this.vacancyResultsKey) {
      super.onFilterBarResults(event)
    }
  }

  onFilterBarTitleChanged (event) {
    if (!this.vacancyResultsKey) {
      super.onFilterBarTitleChanged(event)
    }
  }

  onFilterBarCaptionChanged (event) {
    if (!this.vacancyResultsKey) {
      super.onFilterBarCaptionChanged(event)
    }
  }

  onFilterBarShowLoadMore (event) {
    if (!this.vacancyResultsKey) {
      super.onFilterBarShowLoadMore(event)
    }
  }

  onFilterBarHideLoadMore (event) {
    if (!this.vacancyResultsKey) {
      super.onFilterBarHideLoadMore(event)
    }
  }

  onFilterBarVacancyResults (event) {
    if (!this.vacancyResultsKey) {
      return
    }

    if (this.vacancyResultsKey) {
      this.elementItemsContainer.innerHTML = ''
    }

    const data = event.detail[this.vacancyResultsKey] || []

    if (data.title) {
      this.elementTitle.innerHTML = data.title
    }

    if (data.link_title) {
      this.elementLinkTitle.innerHTML = data.link_title
    }

    if (data.link_href) {
      this.elementLink.setAttribute('href', data.link_href)
    }

    if ((data.results || []).length) {
      this.element.classList.remove('card-grid--hidden')
    } else {
      this.element.classList.add('card-grid--hidden')
    }

    Array.from(data.results || []).forEach(result => {
      this.insertNewItem(result)
    })
  }

  insertNewItem (itemHTML) {
    const newElement = document.createElement('div')
    newElement.setAttribute('class', 'card-grid__card-container')
    newElement.innerHTML = itemHTML
    this.elementItemsContainer.appendChild(newElement)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.card-grid')) {
    element.instance = element.instance || new CardGridComponent(element)
  }
})
