
import Component from '../../../scripts/modules/component'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'
import SwiperWrapper from '../../../scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  // loop: true,
  // loopAdditionalSlides: 2
  // Loop is FALSE omdat anders de <h1> ook mee gedupliceerd wordt
  loop: false
}

export default class CarouselHeaderComponent extends Component {
  init () {
    this.container = this.element.querySelector('.swiper-container')
    this.swiperWrapper = new SwiperWrapper(this.container, SWIPER_OPTIONS, [])
  }

  update () {
    //
  }

  destroy () {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.carousel-header')) {
    element.instance = element.instance || new CarouselHeaderComponent(element)
  }
})
