
import required from '../../vendor/scripts/fabrique/modules/required'

export default class Component {
  constructor (element = required()) {
    this.element = element
    this.init()

    this.element.addEventListener('update', event => this.listen(event))
    this.element.addEventListener('destroy', event => this.listen(event))

    return this
  }

  listen (event) {
    console.log(`Event "${event.type}"`, this.element, this.detail)

    if (event.type === 'update') {
      this.update()
    } else if (event.type === 'destroy') {
      this.destroy()
    }
  }

  init () {}

  update () {}

  destroy () {}
}

// TODO: Make this so that it listens to body mutations (use a single, global observer for this!)
// and does onload onunload stuff automatically.
