
import BackgroundImageComponent from '../../atoms/background-image/background-image'
import FilterBarObserver from '../../../scripts/components/filter-bar-observer'

export default class PanelGridComponent extends FilterBarObserver {
  init () {
    super.init()

    if (this.onShowMoreClick) {
      window.addEventListener('filter-bar:request-load-more', this.onShowMoreClick.bind(this))
    }
  }

  onFilterBarResults (event) {
    super.onFilterBarResults(event)

    for (const element of this.element.querySelectorAll('.background-image')) {
      element.instance = element.instance || new BackgroundImageComponent(element)
    }
  }

  insertNewItem (itemHTML) {
    const newElement = document.createElement('div')
    const classes = ['panel-grid__panel-container']

    if (itemHTML.indexOf('panel--size-wide') >= 0) {
      classes.push('panel-grid__panel-container--size-wide')
    }

    newElement.setAttribute('class', classes.join(' '))

    newElement.innerHTML = itemHTML
    this.elementItemsContainer.appendChild(newElement)
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.panel-grid')) {
    element.instance = element.instance || new PanelGridComponent(element)
  }
})
