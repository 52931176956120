
import Component from '../../../scripts/modules/component'

const POPUP_WIDTH = 800
const POPUP_HEIGHT = 480
const CENTER_POPUP = true

export default class ShareButtonComponent extends Component {
  init () {
    this.button = this.element.querySelector('.button')

    if (!this.button) {
      return
    }

    this.href = this.button.getAttribute('href')
    this.textSpan = this.button.querySelector('.button__span, .link__span')
    this.title = this.textSpan.innerText || ''

    this.element.addEventListener('click', event => this.onClick(event))
  }

  update () {
    //
  }

  destroy () {
    //
  }

  onClick (event) {
    if (this.element.classList.contains('share-button--email')) {
      return
    }

    if (this.openSharePopup()) {
      event.preventDefault()
    }
  }

  openSharePopup () {
    const width = Math.max(window.innerWidth / 2, POPUP_WIDTH)
    const height = Math.max(window.innerHeight / 2, POPUP_HEIGHT)
    const top = (window.outerHeight / 2) - (height / 2)
    const left = (window.outerWidth / 2) - (width / 2)

    return window.open(
      this.href,
      this.title,
      (CENTER_POPUP ? `top=${top}, left=${left}, ` : '') +
      `height=${height}, width=${width}, outerWidth=${width}, outerHeight=${height}, menubar=0, toolbar=0, location=0, personalbar=0, status=0, minimizable=1, resizable=1, scrollbar=1`
    )
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.share-button')) {
    element.instance = element.instance || new ShareButtonComponent(element)
  }
})
