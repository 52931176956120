
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'
import GridManager from '../../../scripts/components/grid-manager'

export default class SearchBarComponent extends GridManager {
  init () {
    super.init()

    if (window.SEARCH_BAR_INITIAL) {
      this.update(window.SEARCH_BAR_INITIAL)
    }
  }

  update (data) {
    super.update(data)

    if (data.vacancy_results) {
      fireCustomEvent('filter-bar:vacancy-results', data.vacancy_results)
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.search-bar')) {
    element.instance = element.instance || new SearchBarComponent(element)
  }
})
