import Component from '../../../scripts/modules/component'
import fireCustomEvent from '../../../vendor/scripts/fabrique/modules/fire-custom-event'

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default class TextHeaderForm extends Component {
  init() {
    const isSwapped = this.element.classList.contains('text-header-form--form-first')

    this.waitForForm().then(() => {
      this.form.addEventListener('submit', event => {
        fireCustomEvent('text-header-form:form-submit', {
          originalEvent: event,
          element: this.element,
          form: this.form
        })
      })

      const checkwidth = window.matchMedia( '(max-width: 700px)' )

      if (!isSwapped && !checkwidth.matches) {
        this.formcontainer = this.element.querySelector('.text-header-form__form-wrapper-inner')
        this.textcontainer = document.querySelector('.text-header-form__text-wrapper')
        this.textcontainer.style.minHeight = ((this.formcontainer.offsetHeight - 100) + 'px')
      }
    })

    // Accordion
    this.button = this.element.querySelector('.text-header-form__accordion-title')
    this.region = this.element.querySelector('.text-header-form__accordion-text')

    if (!this.button || !this.region) {
      return
    }

    this.button.addEventListener('click', () => this.toggleRegion())
  }

  toggleRegion () {
    if (this.button.getAttribute('aria-expanded') === 'true') {
      this.region.setAttribute('aria-hidden', true)
      this.button.setAttribute('aria-expanded', false)
      this.button.classList.remove('accordion--expanded')
    } else {
      this.region.setAttribute('aria-hidden', false)
      this.button.setAttribute('aria-expanded', true)
      this.button.classList.add('accordion--expanded')

      const rect = this.region.getBoundingClientRect()
      window.scroll({ top: (window.pageYOffset + rect.top) - (window.innerHeight / 3), left: 0, behavior: 'smooth' })
    }
  }

  onClick(event) {
    event.preventDefault();

    this.target = document.querySelector('.text-header-form__form-wrapper');

    if (!this.target) {
      // Fallback - If we don't have a target, assume that we want to scroll exactly one screen height from the top.
      window.scroll({ top: window.innerHeight, behavior: 'smooth' });
    } else {
      // Regular behavior - Scroll to the main element.
      this.target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  /**
   * waitForForm
   *
   * Since we load content asynchronously, we don't know when it arrives, therefor we poll
   * until we see it.
   */
  async waitForForm() {
    let t = 100
    this.form = this.element.querySelector('form')
    while (!this.form) {
      await sleep(t)
      this.form = this.element.querySelector('form')
      t *= 2
    }
    return this.form
  }

  update() {
    //
  }

  destroy() {
    //
  }
}

window.addEventListener('DOMContentLoaded', () => {
  for (const element of document.querySelectorAll('.text-header-form')) {
    element.instance = element.instance || new TextHeaderForm(element)
  }
})
